import React, {useContext} from 'react';
import {AuthContext} from "../context/Context";
import {useNavigate} from "react-router-dom";
import IcoTrash from "./IcoTrash";
import IcoList from "./IcoList";

const OnePartner = (props) => {
    // states and vars
    const navigate = useNavigate()
    const {Q_, Lg_, isSysDat, deleteClient, setPopClient, setPopClientCur, ordersReload, setOrdersReload, setFilterPartner, openMessage} = useContext(AuthContext)

    return (
        <div className="one-partner" onClick={() => {
            Q_('.popup-client').style.display = 'grid'
            let tmpClient = isSysDat.companies[props.thisPartner.id]
            setPopClientCur('partner')
            setPopClient(tmpClient)
        }}>
            <div className="one-partner-osn">
                <p>{props.thisPartner.nm} <span>- id:{props.thisPartner.id}</span></p>
                <p>{isSysDat.cities[props.thisPartner.osn[2]]}</p>
                <p>{Lg_('auth-id') == 5 ? 'скрыто для вас' : props.thisPartner.osn[3]}{props.thisPartner.osn[4] && Lg_('auth-id') != 5 ? ', '+props.thisPartner.osn[4] : ''}</p>
                <div className="one-partner-list" data-tooltip="Показать заказы, где этот поставщик является исполнителем"
                     onClick={(e) => {
                         e.preventDefault()
                         e.stopPropagation()
                         Q_('.popup-wait').style.display = 'grid'
                         navigate('/orders/')
                         setTimeout(function () {
                             setOrdersReload(!ordersReload)
                             setFilterPartner(props.thisPartner.id)
                         },1500)
                     }}
                ><IcoList/></div>
                <div className="one-partner-delete" data-tooltip="Удалить"
                     onClick={(e) => {
                         e.preventDefault()
                         e.stopPropagation()
                         if(Lg_('auth-id') == 5){
                             openMessage() // if guest account
                         }else{
                             deleteClient(props.thisPartner.id)
                         }
                     }}
                ><IcoTrash/></div>
            </div>
            {props.thisPartner.fiz[0]
                ?   <div className="one-partner-fiz">
                        <p>Физ.лицо: {props.thisPartner.fiz[1]}</p>
                        <p>{Lg_('auth-id') == 5 ? 'скрыто для вас' : props.thisPartner.fiz[2] ? `Паспорт: ${props.thisPartner.fiz[2]} ${props.thisPartner.fiz[3]}` : ''}</p>
                        <p>{Lg_('auth-id') == 5 ? 'скрыто для вас' : props.thisPartner.fiz[7] ? `День рождения: ${props.thisPartner.fiz[7]}` : ''}</p>
                    </div>
                :   ''
            }
            {props.thisPartner.ur[0]
                ?   <div className="one-partner-ur">
                        <p>Юр.лицо: {props.thisPartner.ur[1]} {props.thisPartner.ur[2] ? `"${props.thisPartner.ur[2]}"` : ''}</p>
                        <p>{Lg_('auth-id') == 5 ? 'скрыто для вас' : props.thisPartner.ur[4] ? `ИНН: ${props.thisPartner.ur[4]}` : ''}</p>
                        <p>{props.thisPartner.ur[11] ? `Директор: ${props.thisPartner.ur[11]}` : ''}</p>
                    </div>
                :   ''
            }
            {props.thisPartner.gos[0]
                ?   <div className="one-partner-gos">
                        <p>Гос.учереждение: {props.thisPartner.gos[1]}</p>
                        <p>{props.thisPartner.gos[2] ? `Адрес: ${props.thisPartner.gos[2]}` : ''}</p>
                    </div>
                :   ''
            }
        </div>
    )
}

export default OnePartner