import axios from "axios";
let addrOrders = 'https://cabinet-control.zabor-knx.ru/orders/';
let addrSubOrders = 'https://cabinet-control.zabor-knx.ru/suborders/';
let addrClients = 'https://cabinet-control.zabor-knx.ru/clients/';
let addrDocs = 'https://cabinet-control.zabor-knx.ru/docs/';
let addrNoms = 'https://cabinet-control.zabor-knx.ru/noms/';

export  default class Loadit {
    // system
    static async getSystem(){
        const response = await axios.get(addrOrders + '?sysdat=1')
        return response.data
    }
    static async getLogin(name, pswd){
        if(document.querySelector('.popup-wait')){
            document.querySelector('.popup-wait').style.display = 'grid'
        }
        const userData = {
            password: pswd,
        }
        const response = await axios.post(
            addrOrders + '?user2=' + name,
            JSON.stringify(userData),
        )
        return response.data
    }
    static async autoLoginCheck(name, pswd){
        if(document.querySelector('.popup-wait')){
            document.querySelector('.popup-wait').style.display = 'grid'
        }
        const userData = {
            password: pswd,
        }
        const response = await axios.post(
            addrOrders + '?user1=' + name,
            JSON.stringify(userData),
        )
        return response.data
    }

    // orders
    static async getOrders(str, count, offset, city, step, client, partner, worker){
        let cityParam = ''
        if(localStorage.getItem('auth-role-cities') && localStorage.getItem('auth-role-cities') == 1){
            cityParam = '&city='+localStorage.getItem('auth-city-id')*1
        }else if(city){
            cityParam = '&city='+city
        }

        let tmpStep = ''
        if(step){
            tmpStep = '&getstep='+step
        }

        let tmpClient = ''
        if(client){
            tmpClient = '&getclient='+client
        }

        let tmpPartner = ''
        if(partner){
            tmpPartner = '&getpartner='+partner
        }

        let tmpWorker = ''
        if(worker){
            tmpWorker = '&getworker='+worker
        }

        let tmpCount = ''
        if(count){
            tmpCount = '&getcount=' + count
        }

        let tmpOffset = ''
        if(offset){
            tmpOffset = '&getoffset=' + offset
        }

        const response = await axios.get(addrOrders + '?get=' + str + tmpCount + tmpOffset + cityParam + tmpStep + tmpClient + tmpPartner + tmpWorker)

        // send data to console for workers
        if(localStorage.getItem('auth') && localStorage.getItem('auth-id') == 1){
            console.log('----------------------ORDERS:')
            console.log(response.data)
        }

        return response.data
    }
    static async getOneOrder(id){
        const response = await axios.get(addrOrders + '?getone=' + id)
        if(localStorage.getItem('auth') && localStorage.getItem('auth-id') == 1){
            console.log('----------------------ONE-ORDER:')
            console.log(response.data)
        }
        return response.data
    }
    static async saveOrder(id, obj, who){
        document.querySelector('.popup-wait').style.display = 'grid'
        const saveData = obj
        const response = await axios.post(
            addrOrders + '?saveorder=' + id + '&who=' + who,
            JSON.stringify(saveData),
        )
        return response.data
    }
    static async checkOrder(str){
        document.querySelector('.popup-wait').style.display = 'grid'
        const response = await axios.get(addrOrders + '?checkorder=' + str)
        return response.data
    }
    // suborders
    static async createSubOrder(who, papa, papaRoot, clone, sbType){
        document.querySelector('.popup-wait').style.display = 'grid'

        let tmpClone = ''
        if(clone){
            tmpClone = '&doclone=1'
        }

        let tmpType = 1
        if(sbType){
            tmpType = sbType
        }

        const response = await axios.get(addrSubOrders + '?create=1&who=' + who + '&papa=' + papa + '&paparoot=' + papaRoot + tmpClone + '&sbtype=' + tmpType)
        return response.data
    }
    static async getPaOrders(type, count, offset, city, step, client, partner, worker){
        let cityParam = ''
        if(localStorage.getItem('auth-role-cities') && localStorage.getItem('auth-role-cities') == 1){
            cityParam = '&city='+localStorage.getItem('auth-city-id')*1
        }else if(city){
            cityParam = '&city='+city
        }

        let tmpStep = ''
        if(step){
            tmpStep = '&getstep='+step
        }

        let tmpClient = ''
        if(client){
            tmpClient = '&getclient='+client
        }

        let tmpPartner = ''
        if(partner){
            tmpPartner = '&getpartner='+partner
        }

        let tmpWorker = ''
        if(worker){
            tmpWorker = '&getworker='+worker
        }

        let tmpCount = ''
        if(count){
            tmpCount = '&getcount=' + count
        }

        let tmpOffset = ''
        if(offset){
            tmpOffset = '&getoffset=' + offset
        }

        const response = await axios.get(addrSubOrders + '?get=' + type + tmpCount + tmpOffset + cityParam + tmpStep + tmpClient + tmpPartner + tmpWorker)

        // send data to console for workers
        if(localStorage.getItem('auth') && localStorage.getItem('auth-id') == 1){
            console.log('----------------------PA-ORDERS:')
            console.log(response.data)
        }

        return response.data
    }
    static async getSubOrder(str){
        const response = await axios.get(addrSubOrders + '?getone=' + str)
        if(localStorage.getItem('auth') && localStorage.getItem('auth-id') == 1){
            console.log('----------------------SUBORDER:')
            console.log(response.data)
        }
        return response.data
    }
    static async saveSubOrder(id, obj){
        document.querySelector('.popup-wait').style.display = 'grid'
        const saveData = obj
        const response = await axios.post(
            addrSubOrders + '?saveorder=' + id,
            JSON.stringify(saveData),
        )
        return response.data
    }
    // orders & suborders
    static async cloneOrder(str){
        document.querySelector('.popup-wait').style.display = 'grid'
        const response = await axios.get(addrOrders + '?clone=' + str)
        return response.data
    }
    static async deleteOrder(str){
        document.querySelector('.popup-wait').style.display = 'grid'
        const response = await axios.get(addrOrders + '?delete=' + str)
        return response.data
    }

    // clients
    static async saveClient(id, obj, who){
        document.querySelector('.popup-wait').style.display = 'grid'

        const response = await axios.post(addrClients + '?saveclient=' + id + '&who=' + who, obj, {
            headers: {
                'accept': 'application/json',
                'Content-Type': `multipart/form-data; boundary=${obj._boundary}`,
            }
        })
        return response.data
    }
    static async deleteClient(str){
        document.querySelector('.popup-wait').style.display = 'grid'
        const response = await axios.get(addrClients + '?delclient=' + str)
        return response.data
    }

    // stock
    static async getOneNom(id){
        document.querySelector('.popup-wait').style.display = 'grid'
        const resp = await axios.get(addrNoms + '?onenom=' + id)
        if(localStorage.getItem('auth') && localStorage.getItem('auth-id') == 1){
            console.log('----------------------ONE-NOM:')
            console.log(resp.data)
        }
        return resp.data
    }
    static async saveStock(id, str){
        document.querySelector('.popup-wait').style.display = 'grid'
        const response = await axios.post(addrNoms + '?savestock=' + id, str)
        return response.data
    }
    static async getHelp(){
        const resp = await axios.get(addrNoms + '?help=1')
        if(localStorage.getItem('auth') && localStorage.getItem('auth-id') == 1){
            console.log('----------------------HELP:')
            console.log(resp.data)
        }
        return resp.data
    }

    // KP
    static async getKP_texts(){
        const response = await axios.get(addrDocs + '?kp=1')
        return response.data
    }
    static async saveKP_text(id, arr){
        document.querySelector('.popup-wait').style.display = 'grid'
        const saveData = arr
        const response = await axios.post(
            addrDocs + '?savekptxt=' + id,
            JSON.stringify(saveData),
        )
        return response.data
    }
    static async saveKP_pic(id, obj){
        document.querySelector('.popup-wait').style.display = 'grid'
        const response = await axios.post(addrDocs + '?savekppic=' + id, obj, {
            headers: {
                'accept': 'application/json',
                'Content-Type': `multipart/form-data; boundary=${obj._boundary}`,
            }
        })
        return response.data
    }
    static async saveKP_set(obj){
        document.querySelector('.popup-wait').style.display = 'grid'
        const response = await axios.post(addrDocs + '?savekpset=1', obj, {
            headers: {
                'accept': 'application/json',
                'Content-Type': `multipart/form-data; boundary=${obj._boundary}`,
            }
        })
        return response.data
    }
    static async deleteKPpic(str){
        document.querySelector('.popup-wait').style.display = 'grid'
        const response = await axios.get(addrDocs + '?delkppic=' + str)
        return response.data
    }
    static async deleteKPtxt(str){
        document.querySelector('.popup-wait').style.display = 'grid'
        const response = await axios.get(addrDocs + '?delkptxt=' + str)
        return response.data
    }
    static async deleteKPset(str){
        document.querySelector('.popup-wait').style.display = 'grid'
        const response = await axios.get(addrDocs + '?delkpset=' + str)
        return response.data
    }
}